import React from 'react'
import './Home1.css'
import { Grid } from '@mui/material'
import {Link} from '@mui/material'
// import profilepic from '../../../assets/1671829300527-01.jpeg'
import profilepic from '../../../assets/images/dhwanilogo.png'
const Home1 = () => {
  return (
    <div id="root" className="home1-main-body">
      <Grid container lg={12} item>
        <Grid lg={12} md={12} sm={12} xs={12} xl={12}>
          <div className="main-content ">
            <img src={profilepic} alt="" />
            {/* <img src="https://replicate.delivery/mgxm/0958ab0c-8d26-45f8-a5f1-a27a1f2259cc/baby.jpg"/> */}
            <span>- DHWANI JEWELS -</span>
            <span>Jewelry that Makes Heads Turn | For Those Who Dare to Shine Bright </span>
          </div>
          <div className="icons ">
          <a href='https://www.facebook.com/profile.php?id=61565244197055'><i class="fa-brands fa-facebook"></i></a>
            <a href='https://https://www.youtube.com/@Dhwani_jewels'><i  class="fa-brands fa-youtube"></i></a>
            <a href='https://www.instagram.com/dhwani__jewels/'><i  class="fa-brands fa-instagram"></i></a>
            
            <a href='https://mail.google.com/mail/u/0/#inbox?compose=GTvVlcSPFqzlgrNTKNxQqdSlzLHlnQPhZbfqGShFPlwkWRfzHNjcjlxfHLRDwVhNbJtFDVHGrQWhT'><i class="fa-solid fa-envelope"></i></a>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Home1
