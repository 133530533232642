import React from 'react'
import './Home3.css'
import { Link } from '@mui/material'
import { motion } from 'framer-motion'
const Home3 = () => {
  return (
    <div className='home3-main'>
      <div className="hireme">
        <motion.div whileInView={{ y: [25,0] }}
        transition={{ ease: "easeIn", duration: 0.3 }}  className='hireme-heading'><h2>Unlock Your Sparkle!</h2></motion.div>
        <p>"Jewelry is the perfect way to express who you are."</p>
      </div>
      <a  className='btn btn-default btn-lg' href="https://forms.gle/nLhoztKwNcF7xkij6">Order now
      </a>
      
    </div>
  )
}

export default Home3
