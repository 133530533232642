import React from 'react'
import './About.css'
import { Grid } from '@mui/material'
import { motion } from 'framer-motion'
import aboutimage from '../../assets/images/saraswati_kammal.jpg'
const About = () => {
  return (
    <div id="root" className="about-page">
      <motion.div
        whileInView={{ y: [35, 0] }}
        transition={{ ease: 'easeIn', duration: 0.2 }}
        className="about-topside"
      >
        About Us
      </motion.div>
      <div className="about-lowerside container-fluid">
        <Grid container item lg={12} >
          <Grid lg={4} xl={4} md={4} sm={12} xs={12}>
            <motion.div
              whileInView={{ y: [30, 0] }}
              transition={{ ease: 'easeIn', duration: 0.5 }}
              className="hero-image"
            >
              <img src={aboutimage} alt="" />
              {/* <img src='https://drive.google.com/drive/folders/1-KO7Huyx9S7gWsF_g7pnS8arKe_G_4Lt'/> */}
            </motion.div>
          </Grid>
          <Grid lg={8} xl={8} md={8} sm={12} xs={12}>
            <motion.div whileInView={{ y: [35, 0] }}
              transition={{ ease: 'easeIn', duration: 0.3 }}
               className="about-hellothere">
              <span>Welcome to Dhwani Jewels – Where Elegance Meets Artistry!</span>
            </motion.div>
            <motion.div whileInView={{ y: [35, 0] }}
              transition={{ ease: 'easeIn', duration: 0.4 }} className="about-description">

              <span>At Dhwani Jewels, we believe that every piece of jewelry tells a story, a melody of love, beauty, and artistry that resonates with your unique style. Founded by passionate artisans and design enthusiasts, our brand is dedicated to crafting exquisite jewelry that captures the essence of elegance and sophistication.


                <br />
                <br />
                Each creation at Dhwani Jewels is a harmonious blend of traditional craftsmanship and contemporary design, showcasing a stunning array of rings, necklaces, bracelets, and earrings that celebrate life's precious moments. Whether you're searching for the perfect accessory for a special occasion or a timeless piece to cherish, our curated collections offer something for every taste and preference.
                <br />
                <br />
                Join us on a journey of self-expression and celebration. Explore our stunning collections and discover how Dhwani Jewels can add a touch of magic to your life. Because at Dhwani Jewels, we don’t just create jewelry; we create memories that last a lifetime.


              </span>
            </motion.div>
            <div className="about-button">
              <a href='https://forms.gle/97qwJp7baWgcTxq2A'><button className='btn btn-success'><span>Your Queries Matter!</span></button></a>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default About
