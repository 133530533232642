import React from 'react'
import './Contact.css'
import profilepic from '../../assets/images/dhwanilogo.png'
import { motion } from 'framer-motion'
const Contact = () => {
  return (
    <div className='contact'>
      <div className="first-half-contact">
        <div className="contact-image">
          <img src={profilepic} alt="" />
        </div>
      </div>
      <div className="second-half-contact">
        <div className="contact-icons">
        <div className="icons ">
            <a href='https://www.facebook.com/profile.php?id=61565244197055'><i class="fa-brands fa-facebook"></i></a>
            <a href='https://https://www.youtube.com/@Dhwani_jewels'><i  class="fa-brands fa-youtube"></i></a>
            <a href='https://www.instagram.com/dhwani__jewels/'><i  class="fa-brands fa-instagram"></i></a>
            <a href='https://mail.google.com/mail/u/0/?ogbl#inbox?compose=GTvVlcSPFrGqjjNlmZDbDMlGpnlfCqkrlhppcZVRHmwMJsdSBZwwpZSkzRjCFBdbSrDpFJvBTkvKg'><i class="fa-solid fa-envelope"></i></a>
          </div>
        </div>

        <div className="about-button">
              <a href='https://forms.gle/97qwJp7baWgcTxq2A'><button className='btn btn-success'><span>Your Queries Matter!</span></button></a>
        </div>
      </div>
    </div>
  )
}

export default Contact
