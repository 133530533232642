import React from 'react'
import './Home2.css'
import { Fade, Grid, Hidden } from '@mui/material'

import { color, motion } from "framer-motion"
import { AnimatePresence } from "framer-motion";
import 'react-circular-progressbar/dist/styles.css'
import ProgressBar from 'react-animated-progress-bar'

import CountUp from 'react-countup'

const Home2 = () => {
  const maxValue = 750;
  return (
    <div className="home2">
      <div className="submain">
        <motion.div
        initial="hidden"
        whileInView={{ y: [30,0] }}
        transition={{ ease: "easeIn", duration: 0.2 }}><h1 className='skills'>Celebrate life’s moments with Dhwani❤️</h1></motion.div>
      </div>
      <motion.div initial="hidden"
        whileInView={{ y: [50,0] }}
        transition={{ ease: "easeIn", duration: 0.3 }} className="progress-bar-section">
        <Grid lg={12} item container>
          <Grid lg={3} xl={3} md={4} sm={4} xs={6}>
            <h3 className='trust'>
              <CountUp start={240} end={750} duration={14.5} />+ Customers
            </h3>
          </Grid>
          <Grid lg={3} xl={3} md={4} sm={4} xs={6}>
            
          <h3 className='trust'>
              <CountUp start={900} end={1100} duration={14.5} />+ Pieces Sold
            </h3>
          </Grid>
          <Grid lg={3} xl={3} md={4} sm={4} xs={6}>
            
          <h3 className='trust'>
              <CountUp start={170} end={400} duration={14.5} />+    
              on online 
            </h3>
          </Grid>
          <Grid lg={3} xl={3} md={4} sm={4} xs={6}>
          <h3 className='trust'>
              <CountUp start={25} end={50} duration={14.5} />+       customer stories
            </h3>
          </Grid>
          <Grid lg={3} xl={3} md={4} sm={4} xs={6}>
            <ProgressBar
              className="progress-bar"
              width="230"
              trackWidth="13"
              percentage="100"
            />
            <h3>Trustable</h3>
          </Grid>
          <Grid lg={3} xl={3} md={4} sm={4} xs={6}>
            <ProgressBar
              className="progress-bar"
              width="230"
              trackWidth="13"
              percentage="100"
            />
            <h3>guarantee</h3>
          </Grid>
        </Grid>
      </motion.div>
    </div>
  )
}

export default Home2
