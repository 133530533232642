import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';





import React from 'react'
import './Work.css'

import { color, motion } from 'framer-motion'
import { Grid } from '@mui/material'
import Carousel from 'react-bootstrap/Carousel'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'


//images_1
import saraswati_kammal from '../../assets/images/saraswati_kammal.jpg'
import saraswati_kammal2 from '../../assets/images/saraswati_kammal_2.jpg'

//images_2
import saraswati_mala from '../../assets/images/saraswati_mala.jpg'

//images_3
import agaja_mala from '../../assets/images/agaja_necklace.jpg'

//images_4
import yashi_mala from '../../assets/images/yashi_glossy_round_necklace.jpg'

//images_5
import kemp_mala from '../../assets/images/kemp_necklace.jpg'

//images_6
import stainless_steel_bracelet from '../../assets/images/stainless_steel_b.JPG'

//images_7
import stainless_steel_bangle from '../../assets/images/steel_bangle.jpg'

//images_8
import traditional_alloy from '../../assets/images/traditional_alloy.jpg'

//images_9
import traditional_earings from '../../assets/images/traditional_earings.jpg'

//images_10
import traditional_jumkas from '../../assets/images/traditional_jumka.jpg'

//images_11
import sangriya_alloy from '../../assets/images/sangriya_alloy.jpg'

//images_12
import apple_earings from '../../assets/images/apple_earings.jpg'

//images_13
import peacock_earings from '../../assets/images/peacock_jumkas.jpg'

//images_14
import earing_set1 from '../../assets/images/earing set/1.jpg'
import earing_set2 from '../../assets/images/earing set/2.jpg'
import earing_set3 from '../../assets/images/earing set/3.jpg'
import earing_set4 from '../../assets/images/earing set/4.jpg'
import earing_set5 from '../../assets/images/earing set/5.jpg'
import earing_set6 from '../../assets/images/earing set/6.jpg'

// //images_15
// import  from '../../assets/images/'

const Why = () => {
  const projects = [
    {
      title: 'Saraswati Jumkas',
      description: 'code: D1231 Price: 149/-',
      images: [
        saraswati_kammal,
        saraswati_kammal2, // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'Saraswati mala',
      description: 'code: D1232 Price: 139/-',
      
      images: [
        saraswati_mala,// Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'agaja necklace ',
      description: 'code: D1233 Price: 199/-',
      images: [
        agaja_mala , // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'yashi glossy round necklace',
      description: 'code: D1234 Price: 199/-',
      images: [
        yashi_mala, // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'stainless_steel',
      description: 'code: D1235 Price: 219/-',
      images: [
        stainless_steel_bracelet, // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'Stainless steel bangle',
      description: 'code: D1236 Price: 249/-',
      
      images: [
        stainless_steel_bangle,// Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'traditional alloy choker ',
      description: 'code: D1237 Price: 249/-',
      images: [
        traditional_alloy, // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'kerala traditional earings',
      description: 'code: D1238X6 Price: 299/-',
      images: [
        traditional_earings, // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    }
    ,
    {
      title: 'kerala traditional jumkas',
      description: 'code: D1239 Price: 99/-',
      
      images: [
        traditional_jumkas,// Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'sangriya alloy earings',
      description: 'code: D1240 Price: 69/-',
      images: [
        sangriya_alloy, // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'Apple Earings',
      description: 'code: D1241 Price: 49/-',
      images: [
        apple_earings, // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'Peacock Jumkas',
      description: 'code: D1242 Price: 49/-',
      images: [
        peacock_earings, // Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    },
    {
      title: 'Earings collection',
      description: 'code: D1243 Price: 49/-',
      images: [
        earing_set1,earing_set2,earing_set3,earing_set4,earing_set5,earing_set6// Add more images
      ],
      link: 'https://forms.gle/nLhoztKwNcF7xkij6'
    }
  ]

  return (
    <div>
      <div className="work-main">
        <motion.div
          whileInView={{ y: [40, 0] }}
          transition={{ ease: 'easeIn', duration: 0.2 }}
          className="work"
        >
          <span>Our top notch</span>
        </motion.div>

        <div className="cards container">
          <Grid className="container-fluid" container item spacing={3} lg={12}>
            {projects.map((project, index) => (
              <Grid key={index} md={6} lg={3} sm={12} xs={12} xl={3}>
                <motion.div
                  className="div container"
                  whileInView={{ y: [50, 0] }}
                  transition={{ ease: 'easeIn', duration: 0.2 + index * 0.1 }}
                >
                  <Card style={{ width: '18rem' }}>
                    <Carousel>
                      {project.images.map((image, i) => (
                        <Carousel.Item key={i}>
                          <img
                            className="d-block w-100 image"
                            src={image}
                            alt={`Slide ${i}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <Card.Body >
                      <Card.Title  >{project.title}</Card.Title>
                      <Card.Text className='cardbody'>{project.description}</Card.Text>
                      <a className="card-btn" href={project.link}>
                        <Button variant="primary" >Order Now</Button>
                      </a>
                    </Card.Body>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div
        className="work-submain"
      >
        <motion.div
          whileInView={{ y: [35, 0] }}
          transition={{ ease: 'easeIn', duration: 0.2 }}
          className="my-services"
        >
          <span> Why Choose Us</span>
        </motion.div>
        <Grid lg={12} item container>
          <Grid lg={3} xl={3} md={4} sm={6} xs={12}>
            <motion.div whileInView={{ y: [35, 0] }}
          transition={{ ease: 'easeIn', duration: 0.2 }}
            className="service-body">
              <div className="service-icons">
                <i class="fa fa-gavel"></i>
              </div>
              <span>Premium Quality Materials
              </span>
            </motion.div>
          </Grid>
          <Grid lg={3} xl={3} md={4} sm={6} xs={12}>
            <motion.div whileInView={{ y: [35, 0] }}
          transition={{ ease: 'easeIn', duration: 0.2 }}
            className="service-body">
              <div className="service-icons">
                <i class="fa-solid fa-wand-magic-sparkles"></i>
              </div>
              <span>Authenticity Guarantee</span>
            </motion.div>
          </Grid>
          <Grid lg={3} xl={3} md={4} sm={6} xs={12}>
            <motion.div whileInView={{ y: [35, 0] }}
          transition={{ ease: 'easeIn', duration: 0.2 }}
           className="service-body">
              <div className="service-icons">
                <i class=" 	fa fa-plane"></i>
              </div>
              <span>Global Reach</span>
            </motion.div>
          </Grid>
          <Grid lg={3} xl={3} md={4} sm={6} xs={12} >
            <motion.div whileInView={{ y: [35, 0] }}
          transition={{ ease: 'easeIn', duration: 0.2 }}
           className="service-body">
              <div className="service-icons">
                <i className="	fa fa-shopping-bag"></i>
              </div>
              <span>Ethically Sourced</span>
            </motion.div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Why
